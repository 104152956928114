<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row class="details">
                <b-col lg="12" sm="12">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col md=12>
                                        <div style="font-size: 12px; background-color: rgb(51, 121, 130);"><h5 class="text-dark text-center"  style="color:#ffffff !important">{{$t('globalTrans.dealers_information')}}</h5></div>
                                    <b-table-simple striped bordered small hover>
                                       <tbody>
                                            <b-tr>
                                                <b-td style="width: 20% !important;">{{ $t('globalTrans.dealer_name_title') }}</b-td>
                                                <b-td style="width: 50% !important;">{{ (currentLocale == 'bn') ? data.applicant_address.applicant_name_bn : data.applicant_address.applicant_name }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.registered_title')}}</b-td>
                                                <b-td v-if="data.req_test_tag_without_certificate.registered">{{ CheckRegister(data.req_test_tag_without_certificate.registered) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.registration_no_title')}}</b-td>
                                                <b-td> <span v-if="data.req_test_tag_without_certificate.registration_number">{{ $n(data.req_test_tag_without_certificate.registration_number).replace(/\,/g,'') }}</span></b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.dealer_address_title')}}</b-td>
                                                <b-td>{{ (addreassMaker(data.applicant_address) !== undefined && addreassMaker(data.applicant_address) !== null ? addreassMaker(data.applicant_address).replace(/undefined|null/g, '').trim() : '') }}</b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                        <div style="font-size: 12px; background-color: rgb(51, 121, 130);"><h5 class="text-dark text-center"  style="color:#ffffff !important">{{$t('external_research.organization_information')}}</h5></div>
                                    <b-table-simple striped bordered small hover>
                                       <tbody>
                                            <b-tr>
                                                <b-td style="width: 20% !important;">{{ $t('external_research.office_type') }}</b-td>
                                                <b-td style="width: 50% !important;">{{ getOfficeType(data.req_test_tag_without_certificate.office_type_id) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{ $t('globalTrans.office') }}</b-td>
                                                <b-td>{{ getOfficeName(data.req_test_tag_without_certificate.office_id) }}</b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                        <div style="font-size: 12px; background-color: rgb(51, 121, 130);"><h5 class="text-dark text-center"  style="color:#ffffff !important">{{$t('globalTrans.seed_production_others_information')}}</h5></div>
                                    <b-table-simple class="width-100" striped bordered small hover>
                                       <thead>
                                            <b-tr>
                                                <b-th class="text-center" colspan="4">{{$t('globalTrans.seeds_information')}}</b-th>
                                                <b-th class="text-center" colspan="4">{{$t('globalTrans.production_information')}}</b-th>
                                                <b-th class="text-center" colspan="5">{{$t('globalTrans.others_information')}}</b-th>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{$t('globalTrans.seed_name_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.variety_name_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.amount_seed_lot_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.lot_no_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.producer_name_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.production_year_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.date_packing_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.expiry_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.nature_container_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.date_sampling_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.sample_quantity_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.sample_no_title')}}</b-th>
                                                <b-th>{{$t('globalTrans.locally_produced_important_title')}}</b-th>
                                            </b-tr>
                                       </thead>
                                       <tbody v-for="(infos, index) in data.seed_infos" :key="index">
                                            <b-tr>
                                                <b-td style="" v-if="infos">
                                                        <span v-if="infos.seed_name_id != 6"> {{ currentLocale === 'en' ? infos.seed_name : infos.seed_name_bn }} </span>
                                                        <span v-else-if="infos.seed_name_id == 6"> {{ infos.seed_name_others}} </span>
                                                        <span v-if="infos.seed_name_id == 6">
                                                        ({{ currentLocale === 'en' ? infos.seed_name : infos.seed_name_bn }})
                                                        </span>
                                                </b-td>
                                                <b-td style="" v-if="infos">
                                                        <span v-if="infos.seed_name_id != 6"> {{ currentLocale === 'en' ? infos.seed_variety_name : infos.seed_variety_name_bn}} </span>
                                                        <span v-else-if="infos.seed_name_id == 6"> {{ infos.seed_variety_others_name}} </span>
                                                </b-td>
                                                <b-td style="" v-if="infos">
                                                        {{ currentLocale === 'en' ? infos.seed_amount + seedUnit(infos.unit_id) : $n(infos.seed_amount)+seedUnit(infos.unit_id)}},
                                                </b-td>
                                                <b-td style="" v-if="infos">
                                                        {{ infos.lot_number }},
                                                </b-td>
                                                <b-td style="" v-if="infos">{{ (currentLocale === 'en') ? infos.producer_name_en : infos.producer_name_bn }}</b-td>
                                                <b-td v-if="infos">{{ FiscalDateMaker(infos.production_year) }}</b-td>
                                                <b-td v-if="infos">{{ infos.date_of_packing | dateFormat }}</b-td>
                                                <b-td v-if="infos">{{ infos.date_of_expiry | dateFormat }}</b-td>
                                                <b-td style="">{{ (currentLocale === 'en') ? infos.nature_of_container_en : infos.nature_of_container_bn }}</b-td>
                                                <b-td v-if="infos">{{  infos.date_of_sampling | dateFormat }}</b-td>
                                                <b-td v-if="infos">{{ $n(infos.sample_quantity).replace(/\,/g,'') }} {{ seedUnit(infos.sample_unit_id) }}</b-td>
                                                <b-td v-if="infos">{{  $n(infos.sample_no).replace(/\,/g,'') }}</b-td>
                                                <b-td v-if="infos">{{  Checker(infos.locally_produced_imported) }}</b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.close') }}</b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
    props: ['item'],
    components: {
    },
    created () {
        this.data = this.item
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            data: []
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        FiscalDateMaker (id) {
            const Year = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
               if (Year) {
                   return this.$i18n.locale === 'en' ? Year.text_en : Year.text_bn
               }
        },
        getOfficeName (id) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === parseInt(id))
            if (objectData) {
                return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
            }
        },
        getOfficeType (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === parseInt(id))
            if (this.$i18n.locale === 'bn') {
                return data !== undefined ? data.text_bn : ''
            } else {
                return data !== undefined ? data.text_en : ''
            }
        },
        seedUnit: function (id) {
            const seedUnit = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.filter(item => item.value === id)
            if (seedUnit.length > 0 && seedUnit[0]?.text_en && seedUnit[0]?.text_bn) {
                return this.$i18n.locale === 'en' ? seedUnit[0].text_en : seedUnit[0].text_bn
            } else {
                return ''
            }
        },
        Checker (type) {
          return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.locally_produced') : this.$t('request_testing_tag_without_field_certification_application.imported')
        },
        CheckRegister (type) {
            return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.registered_status') : this.$t('request_testing_tag_without_field_certification_application.un-registered_status')
        },
        addreassMaker (data) {
            return this.holding(data) + ', ' + data.a_village_name + ', ' + data.a_post_office_name + ', ' + this.unionName(data.a_union_id) + ', ' + this.upazilaName(data.a_upazilla_id) + ', ' + this.districtName(data.a_district_id) + ', ' + this.divName(data.a_division_id)
            // data.a_village_name + ', ' + data.a_holding_number_en + ', ' + data.a_post_office_name
        },
        divName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
        districtName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
        upazilaName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
        unionName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
        holding (data) {
            if (this.$i18n.locale === 'bn') {
                return data.a_holding_number_bn
            } else {
                return data.a_holding_number_en
            }
        }
    }
}

</script>
<style lang="scss">
    .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
