<template>
    <b-overlay :show="isLoading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                <b-row style="
                display: flex;
                flex-wrap: wrap;"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h4 class="text-white">{{ $t('certify_form.payment') }}</h4>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-form-group>
                                    <template v-slot:label>
                                        {{ $t('certify_form.payment_method') }}
                                    </template>
                                    <b-form-radio-group
                                        v-model="payment.payment_status"
                                        class="mr-5"
                                        :options="options"
                                        name="radio-inline"
                                    ></b-form-radio-group>
                                </b-form-group>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row style="
                    display: flex;
                    flex-wrap: wrap;"
                    v-show="payment.payment_status === 1"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h4 class="text-white">{{ $t('certify_form.bank_info') }}</h4>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col md="6" >
                                        <ValidationProvider name="Traeasury Challan Code" vid="treasury_challan_code" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_code" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.treasury_challan_code') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="number"
                                                    id="treasury_challan_code"
                                                    v-model="payment.treasury_challan_code"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Treasury Deposited Code" vid="treasury_deposited_amount" :rules="payment.payment_status === 1?'required':''">
                                        <b-form-group class="row" label-cols-sm="6" label-for="treasury_deposited_amount" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.treasury_deposited_amount') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                v-model="payment.treasury_deposited_amount"
                                                id="treasury_deposited_amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Treasury Challan Number" vid="treasury_challan_number" :rules="payment.payment_status === 1?'required':''">
                                        <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_number" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.treasury_challan_number') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                v-model="payment.treasury_challan_number"
                                                id="treasury_challan_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Treasury Challan Date" vid="treasury_challan_date" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_date" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.treasury_challan_date') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    class="date-picker"
                                                    v-model="payment.treasury_challan_date"
                                                    id="treasury_challan_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                   <b-col md="6">
                                    <ValidationProvider name="treasury_challan_file" vid="treasury_challan_file" ref="treasury_challan_file" :rules="payment.payment_status === 1?'required':''">
                                    <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_file" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{ $t('certify_form.treasury_challan_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-file
                                            id="treasury_challan_file"
                                            @change="onTreasuryChange"
                                            v-model="payment.treasury_challan_file"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-file>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Bank Name (En)" vid="bank_name" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="6" label-for="bank_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.bank_name_en') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="payment.bank_name"
                                                id="bank_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Bank Name (Bn)" vid="bank_name_bn" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="6" label-for="bank_name_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.bank_name_bn') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="payment.bank_name_bn"
                                                id="bank_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Branch Name (En)" vid="branch_name" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="6" label-for="branch_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.branch_name_en') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="payment.branch_name"
                                                id="branch_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Branch Name (Bn)" vid="branch_name_bn" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="6" label-for="branch_name_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.branch_name_bn') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="payment.branch_name_bn"
                                                id="branch_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Amount" vid="amount" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="6" label-for="Amount" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('globalTrans.amount') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="payment.amount"
                                                id="amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row style="
                    display: flex;
                    flex-wrap: wrap;"
                    v-show="payment.payment_status === 2"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                        <b-col md="12" lg="12" sm="12">
            <table v-if="history.length" class="table table-bordered">
              <tr>
                <th colspan="4" class="text-center">History</th>
              </tr>
                <tr>
                    <th>{{ $t('fee_name.fee_name') }}</th>
                    <th>{{ $t('globalTrans.amount') }}</th>
                    <th>{{ $t('globalTrans.status') }}</th>
                    <th>{{ $t('globalTrans.action') }}</th>
                </tr>
                <tr v-for="(item, index) in history" :key="index">
                    <td>{{ getFeeName(payment.payment_type_id) }}</td>
                    <td>{{ $n(item.amount) }}</td>
                    <td>
                      <span class="badge badge-success" v-if="item.pay_status === 'success'">{{ $t('globalTrans.success') }}</span>
                      <span class="badge badge-warning" v-else>{{ $t('globalTrans.pending') }}</span>
                    </td>
                    <td class="text-center">
                      <b-button v-if="item.pay_status === 'pending'" @click="saveFormData(2, item.transaction_no)" variant="primary" class="mr-2 btn-sm">{{ $t('externalLrcpn.pay_now') }}</b-button>
                      <p v-else class="badge badge-success">{{ $t('globalTrans.paid') }}</p>
                    </td>
                </tr>
            </table>
            <table v-else class="table table-bordered">
                <tr>
                    <th style="width:50%">{{ $t('certify_form.fee_name') }}</th>
                    <th style="width:50%">{{ $t('globalTrans.amount') }}</th>
                </tr>
                <tr>
                    <td style="width:50%">{{ getFeeName(payment.payment_type_id) }}</td>
                    <td style="width:50%">{{ $n(payment.amount) }}</td>
                </tr>
            </table>
        </b-col>
                        </iq-card>
                    </b-col>
                </b-row>
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button v-if = "payment.payment_status === 1" type="submit" :disabled="isLoading" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        <b-button v-if = "payment.payment_status === 2" @click="saveFormData(1)" variant="primary" class="mr-2 btn-sm">{{ $t('externalLrcpn.pay_now') }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                </div>
                <!-- <pre>{{$store.state.ExternalAgriResearch.agriResearchCommonObj.feePolicy}}</pre>
                <pre>{{payment}}</pre> -->
            </b-form>
        </ValidationObserver>
    </b-overlay>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeTestingPaymentInfo, paymentStore } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'
export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: ['item'],
    data () {
        return {
            isLoading: false,
            saveBtnName: this.id ? this.$t('globalTrans.submit') : this.$t('globalTrans.save'),
            payment: {
                payment_status: 1,
                payment_type_id: '',
                fee_id: '',
                amount: 0
            },
            history: [],
            tmpTreasuryFile: [],
            options: [
                {
                    value: 1,
                    text: this.$t('certify_form.bank')
                },
                {
                    value: 2,
                    text: this.$t('certify_form.ekpay')
                }
            ]
        }
    },
    created () {
        this.item.general_info_id = this.item.id
            const feePolicy = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.feePolicy.find(policy => policy.payment_type_id === 2)
            this.payment.amount = typeof feePolicy !== 'undefined' ? feePolicy.amount * (this.item.seed_infos.length ? this.item.seed_infos.length : 1) : 0
            this.payment.payment_type_id = feePolicy.payment_type_id
            this.payment.fee_id = feePolicy.id
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
        // async checkHistory () {
        //     const params = {
        //     application_id: this.payment.application_id,
        //     service_id: this.payment.service_id
        //     }
        //     this.loadHistory = true
        //     const result = await RestApi.getData(agriResearchServiceBaseUrl, '/application/payment/history', params)
        //     this.loadHistory = false
        //     if (result.success) {
        //     this.history = result.data
        //     } else {
        //     this.history = []
        //     }
        // },
        getFeeName (id) {
            const paymentList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Field Application Fee' : 'আবেদন ফি' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Seed Testing Fee' : 'বীজ পরীক্ষার ফী' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Tag Print Fee' : 'ট্যাগ প্রিন্ট ফী' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Subscription Fee' : 'সাবস্ক্রিপশন ফী' }
            ]
            const fee = paymentList.find(item => item.value === id)
            if (typeof fee === 'undefined') {
                return ''
            } else {
                return fee.text
            }
        },
        async saveFormData (type, transId = '') {
            this.isLoading = true
                let tmpPay = {}
                if (type === 2) {
                    tmpPay = Object.assign(this.payment, { transId: transId }, { general_info_id: this.item.general_info_id }, { type_id: 2 })
                } else {
                    if (this.item.general_info_id) {
                        tmpPay = Object.assign(this.payment, { transId: '' }, { general_info_id: this.item.general_info_id }, { type_id: 2 })
                    } else {
                        tmpPay = Object.assign(this.payment, { transId: '' }, { general_info_id: '' })
                    }
                }
                let result = null
                const loadingState = { loading: false, listReload: false }
                result = await RestApi.postData(agriResearchServiceBaseUrl, paymentStore, tmpPay)
                this.saveloading = false
                loadingState.listReload = true
                this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false })
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    if (result.success === 2) {
                    this.$toast.success({
                        title: 'Success',
                        message: result.message
                    })
                    } else {
                    window.location = result.url
                    }
                    this.$bvModal.hide('modal-payment')
                } else {
                    this.$refs.form.setErrors(result.errors)
                    this.$toast.error({
                    title: 'Error',
                    message: result.message
                    })
                }
            this.isLoading = false
        },
        async saveData () {
            this.isLoading = true
            let mainForm = {}
            mainForm = { ...this.payment, user_id: this.$store.state.Auth.authUser.id, general_info_id: this.item.general_info_id, type_id: 2 }
            var formData = new FormData()
            Object.keys(mainForm).map(key => {
                if (key === 'treasury_challan_file') {
                    if (this.tmpTreasuryFile.name) {
                        formData.append('treasury_challan_file', this.tmpTreasuryFile)
                    } else {
                        formData.append(key, this.payment[key])
                    }
                } else {
                    formData.append(key, mainForm[key])
                }
            })
            let result = null
            result = await RestApi.postData(agriResearchServiceBaseUrl, storeTestingPaymentInfo, formData)
            if (result.success) {
                this.$emit('listenerChild')
                this.$bvModal.hide('modal-5')
                this.$toast.success({
                title: 'Success',
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.isLoading = false
                window.location.reload(true)
            } else {
                this.$toast.error({
                title: 'Error',
                message: this.$t('globalTrans.form_error_msg'),
                color: '#D6E09B'
                })
                this.$refs.form.setErrors(result.errors)
            }
            this.isLoading = false
        },
        onTreasuryChange (e) {
            this.tmpTreasuryFile = e.target.files[0]
        }
    }
}
</script>

<style>

</style>
