import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-receipt'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm) => {
    try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [{
          columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' }
    ]
    // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      const allRows = [
        [
            { text: vm.$t('research_results.seed_test_result'), colSpan: 4, style: 'td', alignment: 'center' },
            {},
            {},
            {}
        ],
        [
            { text: vm.$t('research_results.lab_name'), style: 'td' },
            { text: vm.currentLocale === 'en' ? data.lab_office_name : data.lab_office_name_bn, style: 'td' },
            { text: vm.$t('research_results.seed_source_name'), style: 'td' },
            { text: vm.currentLocale === 'en' ? data.seed_source_name : data.seed_source_name_bn, style: 'td' }
        ],
        [
            { text: vm.$t('seed_report.seed_name'), style: 'td' },
            { text: vm.currentLocale === 'en' ? data.seed_name : data.seed_name_bn, style: 'td' },
            { text: vm.$t('tag_print.seed_variety_name'), style: 'td' },
            { text: vm.currentLocale === 'en' ? data.seed_variety_name : data.seed_variety_name_bn, style: 'td' }
        ],
        [
            { text: vm.$t('seed_report.seed_class'), style: 'td' },
            { text: vm.currentLocale === 'en' ? data.seed_class_name : data.seed_class_name_bn, style: 'td' },
            { text: vm.$t('research_results.organization_address'), style: 'td' },
            { text: (data.organization_address !== undefined && data.organization_address !== null ? data.organization_address.replace(/undefined|null/g, '').trim() : ''), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.lot_no'), style: 'td' },
            { text: data.lot_number, style: 'td' },
            { text: vm.$t('research_results.sample_collection_date'), style: 'td' },
            { text: dateFormat(data.sample_collection_date), style: 'td' }
        ],
        [
          { text: vm.$t('research_results.seed_examination_start_date'), style: 'td' },
          { text: dateFormat(data.seed_exam_start_date), style: 'td' },
          { text: vm.$t('research_results.seed_examination_end_date'), style: 'td' },
          { text: dateFormat(data.seed_exam_end_date), style: 'td' }
        ],
        [
          { text: vm.$t('research_results.test_name_seed_quality'), colSpan: 4, style: 'td', alignment: 'center' },
          {},
          {},
          {}
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          body: allRows
        }
      })
      // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      const allRows2 = [
        [
          { text: vm.$t('research_results.purity_test'), colSpan: 4, style: 'td', alignment: 'center' },
          {},
          {},
          {}
        ],
        [
            { text: vm.$t('research_results.pure_seed_result'), style: 'td' },
            { text: vm.$n(data.pure_seed_result), style: 'td' },
            { text: vm.$t('research_results.pure_seed_quality'), style: 'td' },
            { text: vm.$n(data.pure_seed_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.inert_substance_result'), style: 'td' },
            { text: vm.$n(data.inert_substance_result), style: 'td' },
            { text: vm.$t('research_results.inert_substance_quality'), style: 'td' },
            { text: vm.$n(data.inert_substance_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.others_seed_result'), style: 'td' },
            { text: vm.$n(data.others_seed_result), style: 'td' },
            { text: vm.$t('research_results.others_seed_quality'), style: 'td' },
            { text: vm.$n(data.others_seed_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.seed_of_other_crops_result'), style: 'td' },
            { text: vm.$n(data.seed_of_other_crops_result), style: 'td' },
            { text: vm.$t('research_results.seed_of_other_crops_quality'), style: 'td' },
            { text: vm.$n(data.seed_of_other_crops_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.weed_seeds_result'), style: 'td' },
            { text: vm.$n(data.weed_seed_result), style: 'td' },
            { text: vm.$t('research_results.weed_seeds_quality'), style: 'td' },
            { text: vm.$n(data.weed_seed_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.result'), style: 'td' },
            { text: data.tag_status === 1 ? vm.$t('research_results.satisfied') : data.tag_status === 2 ? vm.$t('research_results.unsatisfied') : '', style: 'td' },
            { text: vm.$t('research_results.comments'), style: 'td' },
            { text: data.purity_test === 1 ? vm.$t('tag.approve') : data.purity_test === 2 ? vm.$t('tag.reject') : '', style: 'td' }
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          body: allRows2
        }
      })
       // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      const allRows3 = [
        [
          { text: vm.$t('research_results.germination_test'), colSpan: 4, style: 'td', alignment: 'center' },
          {},
          {},
          {}
        ],
        [
            { text: vm.$t('research_results.normal_seed_result'), style: 'td' },
            { text: vm.$n(data.normal_seed_result), style: 'td' },
            { text: vm.$t('research_results.normal_seed_quality'), style: 'td' },
            { text: vm.$n(data.normal_seed_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.unusual_seed_result'), style: 'td' },
            { text: vm.$n(data.unusual_seed_result), style: 'td' },
            { text: vm.$t('research_results.unusual_seed_quality'), style: 'td' },
            { text: vm.$n(data.unusual_seed_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.fresh_seed_result'), style: 'td' },
            { text: vm.$n(data.fresh_seed_result), style: 'td' },
            { text: vm.$t('research_results.fresh_seed_quality'), style: 'td' },
            { text: vm.$n(data.fresh_seed_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.hard_seeds_result'), style: 'td' },
            { text: vm.$n(data.hard_seed_result), style: 'td' },
            { text: vm.$t('research_results.hard_seeds_quality'), style: 'td' },
            { text: vm.$n(data.hard_seed_quality), style: 'td' }
        ],
        [
            { text: vm.$t('research_results.dead_seed_result'), style: 'td' },
            { text: vm.$n(data.dead_seed_result), style: 'td' },
            { text: vm.$t('research_results.dead_seed_quality'), style: 'td' },
            { text: vm.$n(data.dead_seed_quality), style: 'td' }
        ],
        [
          { text: vm.$t('research_results.result'), style: 'td' },
          { text: data.tag_status === 1 ? vm.$t('research_results.satisfied') : data.tag_status === 2 ? vm.$t('research_results.unsatisfied') : '', style: 'td' },
          { text: vm.$t('research_results.comments'), style: 'td' },
          { text: data.germination_test === 1 ? vm.$t('tag.approve') : data.germination_test === 2 ? vm.$t('tag.reject') : '', style: 'td' }
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          body: allRows3
        }
      })
      const allRows4 = [
        [
          { text: vm.$t('research_results.humidity_test'), colSpan: 4, style: 'td', alignment: 'center' },
          {},
          {},
          {}
        ],
        [
            { text: vm.$t('research_results.humidity_test_result'), style: 'td' },
            { text: vm.$n(data.humidity_test_result), style: 'td' },
            { text: vm.$t('research_results.humidity_test_quality'), style: 'td' },
            { text: vm.$n(data.humidity_test_quality), style: 'td' }
        ],
        [
          { text: vm.$t('research_results.result'), style: 'td' },
          { text: data.tag_status === 1 ? vm.$t('research_results.satisfied') : data.tag_status === 2 ? vm.$t('research_results.unsatisfied') : '', style: 'td' },
          { text: vm.$t('research_results.comments'), style: 'td' },
          { text: data.moisture_test === 1 ? vm.$t('tag.approve') : data.moisture_test === 2 ? vm.$t('tag.reject') : '', style: 'td' }
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          body: allRows4
        }
      })
      pdfContent.push({ text: '', style: 'space' })

        pdfContent.push(
          {
              table: {
                headerRows: 0,
                widths: ['32%', '18%', '18%', '32%'],
                body: [
                  // 1
                  [
                    { text: (data.lab_user_name == null || data.lab_user_name_bn == null || data.lab_user_name === '' || data.lab_user_name_bn === '') ? '' : '...................................................', style: 'td', alignment: 'left' },
                    {},
                    {},
                    { text: (data.user_name == null || data.user_name_bn == null || data.user_name === '' || data.user_name_bn === '') ? '' : '...................................................', style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$i18n.locale === 'en' ? data.lab_user_name : data.lab_user_name_bn, style: 'td', alignment: 'left' },
                    {},
                    {},
                    { text: vm.$i18n.locale === 'en' ? data.user_name : data.user_name_bn, style: 'td', alignment: 'right' }
                  ],
                  [
                    { text: vm.$i18n.locale === 'en' ? data.lab_designation_name : data.lab_designation_name_bn, style: 'td', alignment: 'left' },
                    {},
                    {},
                    { text: vm.$i18n.locale === 'en' ? data.designation_name : data.designation_name_bn, style: 'td', alignment: 'right' }
                  ],
                  [
                    { text: vm.$i18n.locale === 'en' ? data.lab_office_name_bn : data.lab_office_name_bn, style: 'td', alignment: 'left' },
                    {},
                    {},
                    { text: vm.$i18n.locale === 'en' ? data.office_name : data.office_name_bn, style: 'td', alignment: 'right' }
                  ]
                ]
              },
              layout: 'noBorders',
              alignment: 'center'
          }
        )

        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          tds: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          header1: {
            fontSize: 14,
            margin: [0, 0, 0, 0]
          },
          space: {
            margin: [25, 25, 25, 25]
          },
          spaces: {
            margin: [15, 15, 15, 15]
          },
          main_head: {
            margin: [0, -5, 0, -10],
            alignment: 'center'
          },
          org: {
            margin: [0, 0, 0, 20],
            alignment: 'center'
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
      }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
