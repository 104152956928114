<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" md="12" sm="12">
                  <b-button variant="primary" @click="pdfExport" class="mr-2 mb-2 btn-sm float-right">
                    <i class="fa fa-print"></i> {{  $t('globalTrans.print') }}
                  </b-button>
                  <div id="moneyReceiptArea">
                    <b-table-simple border>
                        <b-tr>
                            <b-th colspan="3" class="text-center">{{ $t('externalUserIrrigation.money_receipt_title') }}</b-th>
                        </b-tr>
                      <slot v-if="payment_status == 2">
                        <b-tr>
                            <b-td>{{ $t('farmerOperator.applicant_name') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ ($i18n.locale === 'en') ? payment.applicant_address.applicant_name : payment.applicant_address.applicant_name_bn }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('externalUserIrrigation.purpose') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ getFeeName(payment.fee_id) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('admission_form.amount') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ $n(payment.amount) }} {{ $t('external_research.taka') }}</b-td>
                        </b-tr>
                        <b-tr v-if="payment.challan">
                          <b-td>{{ $t('external_research.ekpay_trxid') }}</b-td>
                          <b-td>:</b-td>
                          <b-td>{{ payment.challan.ekpay_trxid }}</b-td>
                        </b-tr>
                        <b-tr v-if="payment.challan">
                          <b-td>{{ $t('external_research.scroll_no') }}</b-td>
                          <b-td>:</b-td>
                          <b-td>{{ payment.challan.scroll_no }}</b-td>
                        </b-tr>
                        <b-tr v-if="payment.challan">
                          <b-td>{{ $t('external_research.ref_date') }}</b-td>
                          <b-td>:</b-td>
                          <b-td>{{ payment.challan.ref_date | dateFormat }}</b-td>
                        </b-tr>
                        <b-tr>
                            <td colspan="3" class="text-center">[This is a computer-generated document. No Signature is required]</td>
                        </b-tr>
                      </slot>
                      <slot v-else>
                        <b-tr><b-td>{{ $t('certify_form.treasury_challan_code') }}</b-td><b-td>:</b-td><b-td>{{ payment.treasury_challan_code }}</b-td></b-tr>
<b-tr><b-td>{{ $t('certify_form.amount') }}</b-td><b-td>:</b-td><b-td>{{ payment.amount }}</b-td></b-tr>
<b-tr><b-td>{{ $t('certify_form.treasury_challan_number') }}</b-td><b-td>:</b-td><b-td>{{ payment.treasury_challan_number }}</b-td></b-tr>
<b-tr><b-td>{{ $t('certify_form.treasury_challan_date') }}</b-td><b-td>:</b-td><b-td>{{ payment.treasury_challan_date | dateFormat }}</b-td></b-tr>
<b-tr><b-td>{{ $t('certify_form.treasury_challan_file') }}</b-td><b-td>:</b-td><b-td>
<b v-if="payment.treasury_challan_file">
  <a :href="agriResearchServiceBaseUrl + 'storage/uploads/seed_testing_payment/original/' + payment.treasury_challan_file" target="_blank">
  {{ $t('research_seed.download') }}
  <i class="ri-download-cloud-line" ></i>
  </a>
</b>
</b-td></b-tr>
<b-tr><b-td>{{ $t('certify_form.bank_name') }}</b-td><b-td>:</b-td><b-td>{{ $i18n.locale === 'bn' ? payment.bank_name_bn : payment.bank_name }}</b-td></b-tr>
<b-tr><b-td>{{ $t('certify_form.branch_name') }}</b-td><b-td>:</b-td><b-td>{{ $i18n.locale === 'bn' ? payment.branch_name_bn : payment.branch_name }}</b-td></b-tr>
                      </slot>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getSeedPaymentReceipt } from '../../api/routes'
import MoneyReceipt from './money_receipt'
import MoneyReceiptBank from './money_receipt_bank'

export default {
  props: ['item'],
  mounted () {
    core.index()
  },
  created () {
    this.getPaymentDetails()
  },
  data () {
    return {
      agriResearchServiceBaseUrl,
      loading: false,
      payment_status: 0,
      payment: {}
    }
  },
  methods: {
      getFeeName (id) {
        const paymentList = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Field Application Fee' : 'আবেদন ফি' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Seed Testing Fee' : 'বীজ পরীক্ষার ফী' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Tag Print Fee' : 'ট্যাগ প্রিন্ট ফী' },
            { value: 4, text: this.$i18n.locale === 'en' ? 'Subscription Fee' : 'সাবস্ক্রিপশন ফী' }
        ]
        const fee = paymentList.find(item => item.value === id)
        if (typeof fee === 'undefined') {
            return ''
        } else {
            return fee.text
        }
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Money Receipt' : 'অর্থ প্রদানের রসিদ'
      if (this.payment_status === 2) {
        const purpose = this.getFeeName(this.payment.fee_id)
        MoneyReceipt.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 14, reportTitle, this.payment, purpose, this)
      } else {
        MoneyReceiptBank.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 14, reportTitle, this.payment, this)
      }
    },
    async getPaymentDetails () {
      this.loading = true
      const param = {
        type_id: 2,
        general_info_id: this.item.id
      }
      const result = await RestApi.getData(agriResearchServiceBaseUrl, getSeedPaymentReceipt, param)
      this.loading = false
      if (result.success) {
        this.payment = result.data
        this.payment_status = result.payment_status
      }
    },
    getPurpose (paymentTypeId) {
      if (paymentTypeId === 1) {
        return this.$t('externalUserIrrigation.application_fee')
      } else if (paymentTypeId === 2) {
        return this.$t('externalUserIrrigation.renew_fee')
      } else {
        return this.$t('externalUserIrrigation.security_money')
      }
    },
    print () {
      var printArea = document.getElementById('moneyReceiptArea').innerHTML
      var a = window.open('', '', 'height=500, width=500')
      a.document.write('<html><body>')
      a.document.write(printArea)
      a.document.write('</body></html>')
      a.document.close()
      a.print()
    }
  }
}
</script>
